import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { HOME_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { CustomHeader } from "../../components/custom-header";
import { CustomText } from "../../components/custom-text";
import { ImageGallery } from "../../components/image-gallery";
import { ImageViewer } from "../../components/image-viewer";

export const Home = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();
  const [focusedImage, setFocusedImage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(HOME_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openImageViewer = (src: string) => {
    document.body.style.overflow = "hidden";
    setFocusedImage(src);
  };

  const closeImageViewer = () => {
    document.body.style.overflow = "unset";
    setFocusedImage(undefined);
  };

  return (
    <div className="page-container flex flex-col">
      {focusedImage && focusedImage.length > 0 && (
        <ImageViewer src={focusedImage} onClick={() => closeImageViewer()} />
      )}
      <div className="grid grid-cols-1">
        {contentfulContent?.header1 && (
          <CustomHeader
            className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10"
            contentfulCustomText={contentfulContent.header1}
          />
        )}
        {contentfulContent?.description1 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mb-0 default-px-10"
            contentfulCustomText={contentfulContent.description1}
          />
        )}
        {contentfulContent?.description2 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mb-2 default-px-10"
            contentfulCustomText={contentfulContent.description2}
          />
        )}
        {contentfulContent?.header2 && (
          <CustomHeader
            className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10"
            contentfulCustomText={contentfulContent.header2}
          />
        )}
        {contentfulContent?.image1 && (
          <img
            className="w-full default-w-2/3 default-mt-4 justify-self-center"
            src={contentfulContent.image1}
            alt=""
          />
        )}
        {contentfulContent?.description3 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mb-0 default-px-10"
            contentfulCustomText={contentfulContent.description3}
          />
        )}
        {contentfulContent?.description4 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 default-px-10"
            contentfulCustomText={contentfulContent.description4}
          />
        )}
        {contentfulContent?.header3 && (
          <CustomHeader
            className="theme-text-color-3 theme-font text-5xl mb-0 px-5 default-px-10"
            contentfulCustomText={contentfulContent.header3}
          />
        )}
      </div>
      {contentfulContent?.images && (
        <ImageGallery
          images={contentfulContent.images}
          onClick={(src: string) => openImageViewer(src)}
        />
      )}
    </div>
  );
};
